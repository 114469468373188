'use client'

import { useSignals } from '@preact/signals-react/runtime'
import { COUNTRY } from '@susu/headless-commerce/constants/cookie'
import { customer } from '@susu/headless-commerce/contexts/customer'
import type { CountryConfiguration } from '@susu/headless-commerce/types/CountryConfiguration'
import { getCookie } from '@susu/headless-commerce/utils/cookies/browser'
import { isServer } from '@susu/headless-commerce/utils/environment'
import { isValidCountry } from '@susu/headless-commerce/utils/localeUtils'
import log from '@susu/headless-commerce/utils/log'
import { isNotUndefined } from '@susu/undefined'
import ClientSupportedLocation from './ClientSupportedLocation'

export type CountryData = {
  cookie?: CountryConfiguration
}

export default function ClientLocationBanner() {
  useSignals()

  if (isServer() || !customer.value) {
    return null
  }

  const cookieCountry = getCookie(COUNTRY)
  const cookieCountryIsValid =
    isNotUndefined(cookieCountry) && isValidCountry(cookieCountry.toLowerCase())

  if (!cookieCountryIsValid) {
    log.error({
      method: 'ClientLocationBanner',
      message: 'Cookie country is not valid',
    })

    return null
  }

  return <ClientSupportedLocation />
}
